.App {
  background: #ffffff;
  color: #222;
  font-family: 'Helvetica Neue', Helvetica, sans-serif !important;
  font-weight: 200;
  font-size: 12px;
  /*line-height: 1.6;*/
}

a {
  color: #222;
}

img {
  border: 0;
  padding: 0;
}

/* HEADER */

header {
  padding: 20px 54px 0 54px;
}

.heading {
  font-weight: 300;
  font-size: 16px;
}

.heading a {
  color: #808080;
  text-decoration: none;
}

.subheading {
  color: #777;
  font-weight: 300;
  font-size: 12px;
}

.subheading span:after {
  content: " ";
}

/* NAVIGATION */

nav {
  margin-top: 15px;
}

.nav_main {
  margin: 0;
  padding: 0;
}

.nav_main li {
  list-style: none;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  line-height: 24px;
}

.nav_main li {
  text-transform: uppercase;
}

.nav_main li.set.collapsed > ul {
  display: none;
}

.nav_main a {
  color: #888;
  text-decoration: none;
}

.nav_main a[href="#"] {
  /*color: #808080;*/
}

.nav_set {
  margin: 0;
  margin-left: 1em;
  padding: 0;
}

.nav_set li {
  text-transform: none;
}

/**
 * Mobile navigation menu + icon
 */

.nav_mobile_toggle:hover {
    background-color: #eeeeee;
}

.nav_mobile_title { 
    font-size: 14px;
    line-height: 30px;
    margin-left: 15px;
    text-align: center;
}

.nav_mobile_icon {
  width: 20px;
  height: 30px;
  float: right;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}

.nav_mobile_icon span {
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  background: #000;
  border-radius: 2px;
  opacity: 1;
  left: 0;
  padding: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.nav_mobile_icon span:nth-child(1) {
    top: 8px;
}

.nav_mobile_icon span:nth-child(2),
.nav_mobile_icon span:nth-child(3) {
    top: 14px;
}

.nav_mobile_icon span:nth-child(4) {
    top: 20px;
}

.nav_mobile_icon.open span:nth-child(1) {
    top: 14px;
    width: 0%;
    left: 50%;
}

.nav_mobile_icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.nav_mobile_icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.nav_mobile_icon.open span:nth-child(4) {
    top: 14px;
    width: 0%;
    left: 50%;
}

/* CONTENT/MAIN */
main {
  /*max-height: 85%;*/
  overflow-x: auto;
  white-space: nowrap;
  /*padding-bottom: 12px;*/
}

.page > p {
  margin: 0;
  padding: 0;
}

/* MISC */
.image_wc {

}

.image_wc .caption {

}

.form_password {
  text-align: center;
  font-size: 14px;
}

.form_password input {
  width: 220px;
  margin: 12px 0 0 0;
  padding: 5px;
  border: 2px solid #999 !important;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;

  font-family: 'Lucida Grande', 'Lucida Sans Unicode', Helvetica, Arial, sans-serif;
  font-size: 20px;
  text-align: center;
  color: #333;
}

.form_password input.incorrect:focus {
  outline: #ff4444 auto 5px;
}

.form_password label {
  display: block;
  text-transform: uppercase;
}

.form_password div {
  padding: 10px;
}

/* MOBILE VERSION */
@media screen and (max-width: 667px) {
  header {
    width: 90%;
    margin: 0 auto;
    padding: 0;
  }

  .header_title {
    margin: 20px 0;
    text-align: center;
    font-size: 22px;
  }

  .heading {
    font-size: 22px;
  }

  .subheading {
    color: #777;
    font-size: 12px;
  }

  .subheading span {
    display: inline;
  }

  .nav_main {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .nav_main ul, .nav_main li {
    margin: 0;
    padding: 0;
  }

  .nav_main > li {
    border-top: 1px solid black;
    line-height: 24px;
  }

  .nav_main > li.set,
  .nav_main > li.set + li {
    margin-top: 1px;
  }

  .nav_set_parent,
  .nav_main li:last-child {
    border-bottom: 1px solid black;
  }

  .nav_main a {
    display: block;
    line-height: 28px;
    padding: 0;
  }

  .nav_main a:hover,
  .nav_mobile:hover {
    background-color: #f0f0f0;
  }

  .nav_main a.active {
    font-weight: 400;
  }

  .nav_main.open {
    height: auto;
    opacity: 1;
    -webkit-transition: height 1s linear;
    transition: height 1s linear, opacity 1.25s;
  }

  .nav_main.closed {
    display: none;
    opacity: 0;
    height: 0;
  }

  .nav_mobile {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    height: 30px;
    padding: 0 5px;
    cursor: pointer;
    user-select: none;
  }

  .loading {
    margin: 20px auto;
  }

  main {
    width: 90%;
    margin: 0 auto;
  }

  .page {
    margin: 0 auto;
    text-align: center;
  }

  .page img {
    width: 100%;
    height: auto;
    margin: 10px 0;
  }

  .form_password {
    color: #808080;
    padding: 28px 0;
  }

  .image_wc {
    display: block;
    position: relative;
    text-align: center;
    margin: 10px 0;
  }

  .image_wc:last-child {
    margin-bottom: 24px;
  }

  .image_wc img {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }

  .image_wc span {
    display: block;
    margin: 8px 0 16px;
  }
}

/* DESKTOP VERSION */
@media screen and (min-width: 668px) {
  header {
    position: fixed;
    width: 30%;
    height:100%;
  }

  .subheading span {
    display: block;
  }

  .nav_mobile {
    display: none;
  }

  .nav_main a {
    padding: 2px 0;
  }

  .nav_main a.active {
    margin-left: 4px;
    font-weight: 400;
  }

  main {
    position: absolute;
    left: 30%;
    width: 70%;
    height: 100%;
  }

/*  .page {
    display: block;
    overflow: scroll;
    height: 100vh;
    position: relative;
    white-space: nowrap;
  }*/

  .loading {
    position: absolute;
    top: 25px;
  }

  .page blockquote {
    margin: 0;
  }

  .page img {
    max-height: 85vh;
    width: auto;
  }

  .page {
    position: absolute;
    top: 87px;
  }

  .project {
    position: absolute;
    bottom: 15px;
  }

  .password {
    background: #bebebe;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form_password {
    color: #fff;
  }

  .image_wc {
    display: inline-table;
    position: relative;
  }

  .image_wc img {
    height: 85vh;
    width: auto;
    vertical-align: top;
    margin-right: 10px;
    float: none;
  }

  .image_wc span {
    display: block;
    text-align: center;
  }
}

/* LARGER SCREENS */
@media screen and (min-width: 941px) {
  header {
    width: 288px;
    height: 100%;
  }

  main {
    left: 288px;
    width: calc(100% - 288px);
    height: 100%;
  }
}
